<hr >
<!-- Footer -->
<footer class="page-footer font-small mdb-color lighten-3 pt-4 bg-light">

  <!-- Footer Links -->
  <div class="container text-center text-md-left">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 mr-auto my-md-4 my-0 mt-4 mb-1">

        <!-- Content -->
        <h5 class="font-weight-bold text-uppercase mb-4">Dealbaba.in</h5>
        <p>Dealbaba.in is one of the most popular handpicked bargains site that helps consumers find product deals that exist in real-time. At dealbaba.in, you can find everything that offers you significant savings. Many of the deals posted daily help our visitors save more than 70% off retail price. Out of millions of products offered by thousands of stores,

          Dealbaba.in is a participant in the Amazon Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to amazon.in.” Dealbaba.in is the Best website for deals, offers and discount. We work effortlessly to complete your shopping needs. One stop solution to all your shopping needs.
          Amazon and the Amazon logo are trademarks of Amazon.in, Amazon.com, Inc. or its affiliates.</p>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none">

      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 mx-auto my-md-4 my-0 mt-4 mb-1">

        <!-- Links -->
        <h5 class="font-weight-bold text-uppercase mb-4">About</h5>

        <ul class="list-unstyled">
          <li>
            <p>
              <a routerLink="/about" class="btn">About us</a>
            </p>
          </li>  
          
          <li>
            <p>
              <a routerLink="/disclaimer" class="btn">Disclaimer</a>
            </p>
          </li>  

          <li>
            <p>
              <a routerLink="/privacy-policy" class="btn">Privacy Policy</a>
            </p>
          </li>  

          <li>
            <p>
              <a routerLink="/terms-and-conditions" class="btn">Terms & Conditions</a>
            </p>
          </li> 
         
        </ul>

      </div>
      <!-- Grid column -->

      
      <hr class="clearfix w-100 d-md-none">
            <!-- Grid column -->
            <div class="col-md-2 col-lg-2 text-center mx-auto my-4">

              <!-- Social buttons -->
              <h5 class="font-weight-bold text-uppercase mb-4">Follow Us</h5>
      
              <!--telegram -->
              <a class="" style="color: initial;" href="https://t.me/Dealbabain">
                <!-- <i class="fab fa-linkedin-in fa-lg white-text mr-md-5 mr-3 fa-2x"> </i> -->
                <i class="fab fa-telegram  fa-lg white-text mr-md-5 mr-3 fa-2x"></i>
              </a><br><br>
      
              <!--telegram -->
              <a class="" style="color: initial;" href="https://m.facebook.com/Dealbaba.in/">
                <!-- <i class="fab fa-linkedin-in fa-lg white-text mr-md-5 mr-3 fa-2x"> </i> -->
                <i class="fa fa-facebook fa-lg white-text mr-md-5 mr-3 fa-2x"></i>
              </a>
      
      
            </div>
    

    </div>
    <!-- Grid row -->

<!-- Grid row -->
<p><b>Disclaimer: As an Amazon Associate www.dealbaba.in earn from qualifying purchases</b></p>
<p><small>Deals & Offer Posted here are for Information purpose only and the user making transaction should carefully read Information and Offer's Terms & Condition on Actual offer site/store before making any Transaction. Offer Posted here are just information and does not constitute any Legal contractual right for User and user cannot use the Same for Legal purpose.</small></p>
<p><b>All content, trademarks and logos are copyright of their respective owners.</b></p>
<p>By Using This Site, you Agree to the Terms of Service and Privacy Policy of dealbaba</p>
</div>
<!-- Footer Links -->

  <!-- Copyright -->
  <div style="text-align: center;">
    All trademarks and logos are their respective owners
  </div>
  <div class="footer-copyright text-center py-3">© 2020 Copyright:
    <a href="#"> Dealbaba.in</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
