<div class="container mt-2">
    <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
    <div class="form-group">
        <div class="input-group mb-3">
            <input type="text" class="form-control" [(ngModel)]="product.image" #image="ngModel"  name="image" aria-describedby="heading1" placeholder="Image Link" required>
        </div>
    </div>

    <div class="form-group">
        <div class="input-group mb-3">
            <input type="text" class="form-control" [(ngModel)]="product.name" #name="ngModel"  name="name" aria-describedby="heading1" placeholder="Product Name" required>
        </div>
    </div>

    <div class="input-group mb-3">
        <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Affilate Link </span>
        </div>
        <input type="text" class="form-control" name="url" aria-describedby="basic-addon3" [(ngModel)]="product.url" #url="ngModel">
    </div>

    <div class="form-group">
        <div class="input-group mb-3">
            <textarea class="form-control" [(ngModel)]="product.desc" #desc="ngModel"  name="desc" rows="3" placeholder="Description"></textarea>
        </div>
    </div>

    <div class="input-group mb-3">
        <div class="input-group-prepend">
            <span class="input-group-text">₹</span>
        </div>
        <input type="number" class="form-control" aria-label="Amount (to the nearest dollar)" name="offer" [(ngModel)]="product.offer_price" #offer="ngModel" placeholder="Offer Price">
        <div class="input-group-append">
            <span class="input-group-text">.00</span>
        </div>
    </div>

    <div class="input-group mb-3">
        <div class="input-group-prepend">
            <span class="input-group-text">₹</span>
        </div>
        <input type="number" class="form-control" aria-label="Amount (to the nearest dollar)" name="actual" [(ngModel)]="product.actual_price" #actual="ngModel" placeholder="Actual Price">
        <div class="input-group-append">
            <span class="input-group-text">.00</span>
        </div>
    </div>

    
    <div class="form-check">
        <input class="form-check-input" type="radio" name="logo" id="exampleRadios1" value="amazon" [(ngModel)]="product.logo" #logo="ngModel" checked>
        <label class="form-check-label" for="exampleRadios1">
          Amazon
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="logo" id="exampleRadios2" value="flipkart" [(ngModel)]="product.logo" #logo="ngModel" >
        <label class="form-check-label" for="exampleRadios2">
          Flipkart
        </label>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!heroForm.form.valid">{{buttonValue}}</button>
      </form>
</div>