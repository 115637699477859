<div id="myCarousel" class="carousel slide" data-ride="carousel">
  <!-- Indicators -->
  <!-- <ol class="carousel-indicators">
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
  </ol> -->

  <!-- Wrapper for slides -->
  <div class="carousel-inner">
    <div class="item active" *ngIf="Caro">
      <a href="{{Caro.url}}" target="_blank"><img src="{{Caro.image}}" alt="Amazon" class="responsive mobileView"></a>
    </div>
    
  </div>

