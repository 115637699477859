<div class="card p-1 SpecialCard">
    <div class="card-header p-0 bg-transparent mb-1">
        <div class="row header-top SpecialCard-header">
            <div class="col-3 ">
                <!-- <span class="text-secondary">@iamgroot</span>  -->
            </div>
            <div class="col-9 TimeDuration text-wrap " align="right" id="time">
                <i class='far fa-clock fa-sm'></i>
                <small>{{product.time}}</small> </div>
        </div>
    </div>
    <a class="MainCardAnchore" >
        <div class="card-img mt-4">

            <img class="lazy card-img-top postthumb img-responsive" alt={{product.name}} (click)="ViewProduct(product)"
                src="{{product.image}}" style="width: 100%; height: 7rem;"
                onerror="this.src='assets/logo.jpg'">
            

        </div>
    </a>
    <div class="card-body p-1 mt-4">
        <p class="card-text mb-1 font-weight-bold" style="min-height: 50px;">
            <a class="MainCardAnchore" (click)="ViewProduct(product)">
               {{(product.name | slice:0:60) }} </a>
        </p>
        <div class="row card-text">
            <div class="col-5 card-DiscountPrice pl-2 d-inline">
                <span class="big text-danger font-weight-bolder">{{product | discountConverter}}%</span><span class="small">off </span>
            </div>
            <div class="col-7 " align="right">
                <div class="row">
                    <div class="col-12  card-DealPrice" align="right"><span class="big text-danger font-weight-bolder">₹
                            {{product.offer_price}}</span></div>
                    <div class="col-12 card-OriginalPrice d-inline m-0" align="right"><s> {{product.actual_price}} </s> </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer p-1 bg-transparent">
        <div class="row">
            <div class="col-5  pt-1" align="left">
                <a href="#">
                     <img height="30" width="60" src="{{product | logoConverter}}"
                        alt="{{product.logo}}" onerror="this.style='display:none;'">
                        <!-- <b><p style="color:#00AFB3; font-size:12px">{{product.logo | uppercase}}</p></b> -->
                </a>
            </div>
            <div class="col-7 text-wrap m-0" align="right">
                <a href="{{product | urlConverter}}" target="_blank" class="btn btn-success btn-sm" style="background-color: #00AFB3;">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -4 24 24" class="Buy-Svg" height="16" width="16"
                        preserveAspectRatio="xMinYMin">
                        <path
                            d="M7 16a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM.962 1.923A.962.962 0 0 1 .962 0h1.151c.902 0 1.682.626 1.878 1.506l1.253 5.642c.196.88.976 1.506 1.878 1.506h7.512l1.442-5.77H6.731a.962.962 0 0 1 0-1.922h9.345a1.923 1.923 0 0 1 1.866 2.39L16.5 9.12a1.923 1.923 0 0 1-1.866 1.457H7.122a3.846 3.846 0 0 1-3.755-3.012L2.113 1.923H.962z">
                        </path>
                    </svg> -->
                   <small>Shop Now</small> 
                </a>
            </div>
        </div>
    </div>
</div>