<div class="container mt-2">
    <form (ngSubmit)="FetchProduct()" #loginForm="ngForm">
        <div class="form-group">
            <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                <label class="form-check-label" for="exampleRadios1">
                  Amazon
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                <label class="form-check-label" for="exampleRadios2">
                  Flipkart
                </label>
              </div>
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon3">put your Affilate Link </span>
            </div>
            <input type="text" [(ngModel)]="Link" class="form-control" id="name" name="name" aria-describedby="basic-addon3" #name="ngModel"  required />
            <div [hidden]="name.valid || name.pristine"
             class="alert alert-danger">
          Name is required
        </div>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="!loginForm.form.valid">Submit</button>
    </form>

</div>
