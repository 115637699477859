<br>
<div class="container">
    <h2 class="text-success">View Blogs</h2>
    <div class="row">
        <div class="col-md-12">
            <table class="table">
                <thead class="thead-dark">
                    <tr>                        
                        <th>Image</th>
                        <th>Blog Heading</th>
                        <th>Edit Deal</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let blog of BlogList; index as i">                        
                        <td><img src={{blog.img}} alt="image" width="70" /></td>
                        <td>{{blog.heading}}</td>
                        <td><button class="btn btn-success" (click)="EditBlog(blog)">Edit Deal</button></td>
                        <td><button class="btn btn-danger" (click)="DeleteBlog(blog.id)" >Remove Deal</button></td>
                    </tr>
                </tbody>
            </table>    
        </div>
    </div>

    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item" *ngIf="pageNumber !== 1">
            <a class="page-link" (click)="MoreDeal('previous')" tabindex="-1">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link" (click)="MoreDeal('next')" >Next</a>
          </li>
        </ul>
      </nav>

    

</div> 
<br>
