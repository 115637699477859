<div class="container">

    <br>
    <h1>Terms and Conditions</h1>
    <br>
    <p>We have Made up an agreement of our terms and conditions. In order to use our service, you must read and accept all of the terms and conditions of this agreement and in the Privacy Policy. If you do not agree to be bound by the terms after you read this Agreement, you may not use our service.</p>
    <br>
    <p>1. dealbaba.in doesn’t guarantee that you will get free sample,discount and offers .</p>
    <p>2. dealbaba.in doesn’t sell any product or offer free samples or anything, dealbaba Just provide offers, Free samples or other thing which is available online on actual offer site</p>
    <p>3. You agree that dealbaba.in shall not be responsible or liable for any loss or damage of any sort incurred as result of any such dealings.If there is a dispute between participants on this site, or between users and any third party.</p>
    <p>4. dealbaba.in is not responsible, for any products ordered from our affiliated or non affiliated merchants.</p>
    <p>5. Please read our <a href="https://www.dealbaba.in/privacy-policy">Privacy Policy.</a> In order to use our service you must agree with all terms or information provided in Privacy Policy.</p>
    <p>6. You must be atleast 15 year of age to use our service.</p>
    </div>