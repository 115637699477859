<div class="fix-bottom btn-shadow">


    <a class="btn btn-md btn-primary BuyNowButton m-0 float-right col-6" href="https://www.dealsmagnet.com/buy/14Razp20?ref=dp">
    
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -4 24 24" class="SvgIcon" preserveAspectRatio="xMinYMin">
    <path d="M7 16a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM.962 1.923A.962.962 0 0 1 .962 0h1.151c.902 0 1.682.626 1.878 1.506l1.253 5.642c.196.88.976 1.506 1.878 1.506h7.512l1.442-5.77H6.731a.962.962 0 0 1 0-1.922h9.345a1.923 1.923 0 0 1 1.866 2.39L16.5 9.12a1.923 1.923 0 0 1-1.866 1.457H7.122a3.846 3.846 0 0 1-3.755-3.012L2.113 1.923H.962z"></path>
    </svg>
    <b>Get Deal</b>
    <span class="BuyNowButton-label font-weight-normal pt-1">Redirects to Beardo</span>
    </a>
    <a class="btn btn-md btn-white BuyNowButton m-0 pt-1 float-right col-4 bg-white" href="https://www.dealsmagnet.com/store/beardo-offers?ref=dp">
    
    <img alt="beardo" class="SellerImage mt-1" src="https://www.dealsmagnet.com/Assets/img/Logos/Logo/beardo.jpg"  data-pagespeed-url-hash="2257381536" onload="pagespeed.CriticalImages.checkImageForCriticality(this);">
    <span class="Publisher-label text-dark">Deal on </span>
    </a>
    <a class="btn btn-md btn-primary-blue BuyNowButton m-0 pt-1 float-right col-2 AppOnlyHide">
    
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" class="SvgIcon" onclick="ShareIntent();" width="22" height="22" preserveAspectRatio="xMinYMin"><path d="M7.928 9.24a4.02 4.02 0 0 1-.026 1.644l5.04 2.537a4 4 0 1 1-.867 1.803l-5.09-2.562a4 4 0 1 1 .083-5.228l5.036-2.522a4 4 0 1 1 .929 1.772L7.928 9.24z"></path></svg>
    
    </a>
    
    </div>