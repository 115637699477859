export interface ICaro
{
    id : number,
    image : string,
    url : string,
}

export class Caro implements ICaro
{
    constructor ()
    {

    }
    id: number;
    image: string;
    url:string;
    
}