<div class="container-fluid">
    <div class="row bg-light">
        <div class="col-lg-12" style="padding-left: 0px;">
            <div class="row" style="margin-left: auto;">
                <div class=" col-md-3 col-sm-4 col-6 mb-2 cardStyle" *ngFor="let product of ProductList">
                    <app-card [product]=product (productPass)="UpdateProduct($event)"></app-card>
                </div>
            </div>
        </div>
    </div>
</div>
