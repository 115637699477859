
<div class="text-center" >
    <div class="spinner-border text-secondary" role="status" style="width: 3rem; height: 3rem;" *ngIf="show">
        <span class="sr-only">Loading...</span>
      </div>
</div>     

<div class="container-fluid">
    <div class="row bg-light" style="padding: 8px;">
        <div class="col-lg-12" style="padding-left: 0px;">
            <div class="row" style="margin-left: auto;">
                <div class="col-lg-2 col-md-3 col-sm-4 col-6 mb-2 cardStyle" *ngFor="let product of ProductList">
                    <app-card [product]=product></app-card>
                </div>
            </div>
        </div>
    </div>
</div>
<nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
      <li class="page-item" *ngIf="pageNumber !== 1">
        <a class="page-link" (click)="MoreDeal('previous')" tabindex="-1" style="background-color: #00AFB3;color: white;">Previous Page</a>
      </li>
      <li class="page-item">
        <a class="page-link" (click)="MoreDeal('next')" style="background-color: #00AFB3;     color: white">Next Page</a>
      </li>
    </ul>
  </nav>


