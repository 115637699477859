<div class="container" *ngIf="Product">
    <div class="row">
        <div class="col-md-5 " align="center">
            <div class="row">
                <div class="col-md-12 MainImage-Container border border-right-0 border-top-0 border-danger p-4 ml-3"
                    id="MainImage-Container" align="center">
                    <img class="MainImage mt-4" loading="eager"  
                        alt="{{Product.name}}"
                        src="{{Product.image}}"                    
                            onerror="this.src='assets/logo.jpg'">
                </div>
                <div class="col-md-12">
                    <div class="row ">
                        <div class="col-sm-12" id="UnderImageUser">
                            <div class="float-right text-secondary mt-2">
                                Posted at  {{Product.date}} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-7">
            <div class="row">
                <div class="col-md-12"><br>
                    <h3 class="d-none">About Black &amp; Decker Appliances DCM25-IN 330-Watt 1-Cup Coffee Maker</h3>
                    <label class="ProductName mb-0 font-weight-bolder">{{Product.name}}</label>
                </div>

                <div class="col-md-12 mb-0 mt-0">
                    <hr class="mt-2 m-0">
                    <div class="row">
                        <div class="col-md-10">
                            <img class="mw-100" src="https://www.dealsmagnet.com/Assets/img/special/verified_deal.webp"
                                alt="">
                        </div>
                    </div>

                    <div class="col-md-12">
                        <span class="DiscountedPrice">Price:</span> <span class="big font-weight-bolder"> ₹{{Product.offer_price}}</span>
                        <span class="OriginalPrice ml-4"><small>MRP: </small> <s> ₹{{Product.actual_price}}</s> </span>
                        <span class="Discount ml-4 big text-danger font-weight-bolder">{{Product | discountConverter}}% Off</span>
                    </div>

                    <div class="col-7 text-wrap mt-1 big " align="left" >
                        <a href="{{Product | urlConverter}}" target="_blank" class="btn text-white" style="background-color: #00AFB3;">
                            <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="-3 -4 24 24" class="Buy-Svg" 
                             preserveAspectRatio="xMinYMin">
                                <path
                                    d="M7 16a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM.962 1.923A.962.962 0 0 1 .962 0h1.151c.902 0 1.682.626 1.878 1.506l1.253 5.642c.196.88.976 1.506 1.878 1.506h7.512l1.442-5.77H6.731a.962.962 0 0 1 0-1.922h9.345a1.923 1.923 0 0 1 1.866 2.39L16.5 9.12a1.923 1.923 0 0 1-1.866 1.457H7.122a3.846 3.846 0 0 1-3.755-3.012L2.113 1.923H.962z">
                                </path>
                            </svg>                            
                            Get Deal
                        </a>
                    </div>

                    <div class="col-4">
                        <span class="Publisher-label" style="color:#00AFB3; font-size:12px">Deal on  <img height="30" width="60" src="{{Product | logoConverter}}"
                            alt="{{Product.logo}}" onerror="this.style='display:none;'">
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mw-100" id="BankOfferContainer">
        </div>
    </div>
     <div class="row" *ngIf=Product.desc>
        <div class="col-md-12 mw-100 description-text" oncontextmenu="return false">
            <br>

            <p><strong>Product Details:</strong></p>
            <ul class="a-unordered-list a-vertical a-spacing-none" style="margin-bottom: 0px; margin-left: 18px;" [innerHTML]="Product.desc" >
            </ul>
        </div>
    </div> 

    <div class="row">
        <div class="col-md-12 mw-100" oncontextmenu="return false">
            <br>

            <h3><u class="border-bottom border-dark">How to get this deal:-</u></h3>

            <ul>
                <li>Click On&nbsp;<span style="color: #ff0000;" id="InbuiltBuyNow">Get Deal </span> Button to go to offer page</li>
                <li>
                    <div> Check for latest price & stock availability.</div>
                </li>
                <li>
                    <div>Add to cart & select/update shipping info.</div>
                </li>
                <li>
                    <div>Make payment.</div>
                </li>
            </ul> <br>
        </div>
    </div>

    <div class="row" *ngIf="Product.logo == 'amazon'">
        <p><b>Please note:</b> Sometimes you may see variation in product price due to different seller or offer ended. </p>
        <p><b>Disclaimer:</b> Product prices and availability are accurate as of the {{Product.date}} as indicated and are subject to change. Any price and availability information displayed on Merchant’s Site at the time of purchase will apply to the purchase of this product.
        Dealbaba have no control over and makes no warranty or guarantee regarding the quality, usability, safety, morality or legality of any aspect of the items listed, the truth or accuracy of the listings or the ability of sellers to sell items or honor their coupon or promotion.</p>

        <p><b>Note:</b> Free delivery for Amazon Prime Members or make order over ₹ 499 else ₹ 40 will be additional shipping charge.</p>

        <p><b>Disclosure:</b> As an Amazon Associate I earn from qualifying purchases."</p>
    </div>

    <div class="col-md-12">
            
            <div class="fix-bottom  fixed-bottom ">
    
    <div class="row">
       
        
               
                <!-- <div class="col-4 bg-secondary text-center mt-3 pt-1">
                    <svg width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-share-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M12.024 3.797L4.499 7.56l-.448-.895 7.525-3.762.448.894zm-.448 9.3L4.051 9.335 4.5 8.44l7.525 3.763-.448.894z"/>
                        <path fill-rule="evenodd" d="M13.5 5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm-11-5.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                      </svg>
                    
                </div> -->
                
                <div class="col-6 bg-light text-center ">
                    <span class="Publisher-label" style="color:#00AFB3; font-size:12px">Deal on  <img height="30" width="60" src="{{Product | logoConverter}}"
                        alt="{{Product.logo}}" onerror="this.style='display:none;'">
                    </span>
                </div>

                <div class="col-6 text-center" style="background-color: #00AFB3; ">
                    
                    <a href="{{Product | urlConverter}}" class="text-white">
                        <span class="Publisher-label text-white big1 "  >Get Deal   
                        </span>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-cart4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
                      </svg>
                </a>
                </div>

               
            </div>
            </div>
    </div>
    <div class="row" id="TrendingDealsDiv">
        <div class="col-12">
            <br>
            <br>
            <h4>Trending Deals</h4>
            <app-trending (product)="UpdateProduct($event)"></app-trending>
        </div>
      
    </div>