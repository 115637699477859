
<div class="container-fluid">
    <div class="row bg-light" style="padding: 8px;">
        <div class="col-lg-12" style="padding-left: 0px;">
            <div class="row" style="margin-left: auto;">

                <div class="col-lg-2 col-md-3 col-sm-4 col-6 mb-2 cardStyle" *ngFor="let product of ProductList">
                    <app-card [product]=product></app-card>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center m-4">
    <button class="btn btn-secondary" (click)="MoreDeal()" style="background-color: #00AFB3;">More Deals</button>
</div>
