<br>
<div class="container">
    <h2 class="text-success">View Caro</h2>
    <div class="row">
        <div class="col-md-12">
            <table class="table">
                <thead class="thead-dark">
                    <tr>                        
                        <th>Image</th>
                        <th>Caro Url</th>
                        <th>Edit Caro</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let pro of CaroList; index as i">                        
                        <td><img src={{pro.image}} alt="image" width="70" /></td>
                        <td>{{pro.url}}</td>
                        <td><button class="btn btn-success" (click)="EditCaro(pro)">Edit Caro</button></td>
                        <td><button class="btn btn-danger" (click)="DeleteCaro(pro.id)" >Remove Caro</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div> 
<br>
