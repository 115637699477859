<div class="container">

<br>
    <h2>About Dealbaba.in</h2>
    <hr>

    Dealbaba.in is one of the most popular handpicked bargains site that helps consumers find product deals that exist in
    real-time. At Dealbaba.in, you can find everything that offers you significant savings. Many of the deals posted daily
    help our visitors save more than 70% off retail price. Out of millions of products offered by thousands of stores,<b>  we
        find best deals every day.</b> Even during weekend, we are on lookout for deals so you don't miss a chance to save. <br> <br>
    
    We offer exclusive deals at cheapest quotation. We provide holistic “One-stop shopping” solutions for your every
    requirements. <b> We Post best 100+ deals in a day.</b> <br><br>
    
    We provide many offers in different option of On-going Current Deals, Clearance Sale, end of sale and many more options
    for you. We also offer deals every minute to avails the best of it. Now, you can shop for your favorite Fashion-moods,
    Kids wears, Home and Living, Electronics and Appliances and Personal care products. <br><br>
    
    Here you can find Best Deals, Top Deals, Loot Offers, Recharge offfers, Amazon Pantry Deals, Coupons, Best Online
    Shopping Deals in India, Loot Deals from Top Online Stores <b>Amazon, Flipkart, Paytm, Myntra, Jabong, Pepperfry, Tatacliq
        etc.</b> 

</div>
