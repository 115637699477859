<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
    Launch demo modal
  </button> -->
  
  <!-- Modal -->
  <div  id="exampleModalCenter" class="mt-2" tabindex="-1" >
    <div class="container">
      <div class="content">
        <div class="header">
          <h5 class="title" id="exampleModalLongTitle">Admin Login</h5>
         
        </div>
        <div class="body">
          
          <form (ngSubmit)="onSubmit()" #userForm="ngForm">
            <div class="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input type="email" class="form-control" [(ngModel)]="user.email" #email="ngModel"  name="email" aria-describedby="emailHelp" placeholder="Enter email" required>
              
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Password</label>
              <input type="password" class="form-control" [(ngModel)]="user.password" #password="ngModel"  name="password" placeholder="Password" required>
            </div>
            <button (click)="onSubmit()" type="button" class="btn btn-primary "  [disabled]="!userForm.form.valid">Sign In</button>
          </form>
        </div>
        
      </div>
    </div>
  </div>
